import "bootstrap/dist/css/bootstrap.min.css"
import "bootstrap"
import "/src/assets/scss/style.scss"
import { createApp } from 'vue'
import App from '/src/App.vue'
import router from './router'

import { isNativeApp, appVersionInfo, appVersionUpdateAlert } from "./assets/js/common"
import { appStoreLInk } from "./assets/js/model"


const ftapp = createApp(App)

ftapp.config.globalProperties.window = window
window.vgHost = 'chat3-ec.chatdealer.jp'
window.vgProtocol = 'https'
window.vgPort = '443'
window.vgAtxt = 'PMR37Chat3-ec'
window.vgSid = 1

// アプリでバージョンチェック完了して下記のFunctionが行う
window.appVersionCheckingResult = (require) => {
    appVersionInfo.versionCheckFinished = true
    appVersionInfo.updateRequired = require

    if(require){
        appVersionUpdateAlert()
    }
}


ftapp.use(router)

// Nativeアプリ（iPhone, android）でもウェブビューで利用するのでアプリかどうか確認
isNativeApp.value = ( window.ftGroupAppJsBridge || (window.webkit && window.webkit.messageHandlers.appVersionChecking) ) ? true : false

// Nativeアプリ（iPhone, android）の場合
if(isNativeApp.value){
    setTimeout(() => {  
        ftapp.mount('#ftapp')
    },1000)
}
// ウェブサイトの場合
else{
    ftapp.mount('#ftapp')
}
